import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App);

import '@/utils/base.css';

import router from "./router/index.js" //引入配置路由文件
app.use(router); //记得在mount之前调用


import { Button, Popup, Pagination, Icon, Overlay, Slider, Toast, Popover } from 'vant';
import 'vant/lib/index.css';
app.use(Button);
app.use(Popup);
app.use(Icon);
app.use(Pagination);
app.use(Overlay);
app.use(Slider);
app.use(Toast);
app.use(Popover);

// 复制 npm install --save vue-clipboard2
import VueClipboard from 'vue-clipboard2';
VueClipboard.config.autoSetContainer = true;
app.use(VueClipboard);

import { kilobit, strCutting } from './utils/utils.js';
app.config.globalProperties.$kilobit = kilobit;
app.config.globalProperties.$strCutting = strCutting;


import i18n from './i18n.js'; //多语言
app.use(i18n);

app.mount('#app');

//保证页面跳转时页面在顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})