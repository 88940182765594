import { createI18n } from 'vue-i18n'
export default createI18n({ 
  legacy: false, // 让 setup 函数可以通过 t 访问
  globalInjection: true, // 让 template 可以像 vue2 那样使用 $t 来访问
  locale: localStorage.getItem('Music_lang'), //设置语言
  fallbackLocale:'en-US', // 如果没找到要显示的语言，则默认显示
  silentFallbackWarn:true,// 控制台不打印警告
  messages: {
    'en-US': require('@/assets/lang/en.js')
  }
})