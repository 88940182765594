//路由文件
import { createRouter, createWebHistory } from 'vue-router' //将createRouter、createWebHistory引入vue
const routes = [{
		path: '/', //配置默认路由
		name: 'home', //路由名
		component: () => import("../views/home.vue") //引入该路由使用的组件
	},
	{
		path: '/flag',
		name: 'flag',
		component: () => import("../views/flag.vue")
	},
	{
		path: '/zombie',
		name: 'zombie', //路由名
		component: () => import("../views/dead_address.vue")
	},
	{
		path: '/details/:address',
		name: 'details', //路由名
		component: () => import("../views/details.vue")
	},
	{
		path: '/my',
		name: 'my', //路由名
		component: () => import("../views/my.vue")
	},
	{
		path: '/admin',
		name: 'admin',
		component: () => import("../views/admin.vue")
	},
	{
		path: '/mHome',
		name: 'mHome',
		component: () => import("../views/mobile/home.vue")
	},
	{
		path: '/mZombie',
		name: 'mZombie',
		component: () => import("../views/mobile/dead_address.vue")
	},
	{
		path: '/mDetails/:address',
		name: 'mDetails', //路由名
		component: () => import("../views/mobile/details.vue")
	},
	{
		path: '/mMy',
		name: 'mMy',
		component: () => import("../views/mobile/my.vue")
	}
	

]
const router = createRouter({ //设置为history模式
	history: createWebHistory(),
	routes
})


export default router