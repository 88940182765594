/** 精度处理 npm install --save decimal.js
	val:要处理的数字
	decimal：数字本身精度
	s：要保留的小数位数
 */
export function decimal(val, decimal, s) {
  let d = decimal * 1
  let str = new this.Decimal(val).div(10 ** d).toFixed(d).toString();
  let index = str.indexOf('.');
  return str.substring(0, index+s*1+1);
}
/* 千位符分割
	val：要分割千位的数字
	dec：要保留的小数位数
*/
export function kilobit(val, dec) {
	let str = val + '';
	str = str.replace(/^(\d*)$/, "$1.");
	str = (str + "00000000").replace(/(\d*\.\d\d\d\d\d\d\d\d)\d*/, "$1");
	str = str.replace(".", ",");
	var re = /(\d)(\d{3},)/;
	while (re.test(str))
		str = str.replace(re, "$1,$2");
	str = str.replace(/,(\d\d\d\d\d\d\d\d)$/, ".$1");
	str.replace(/^\./, "0.");
	let index = str.indexOf('.');
	return str.substring(0, index + dec * 1 + 1);
}


export function times(val) {
	var date = new Date(val * 1000);
	// var date = new Date(date * 1000);
	var Y = date.getFullYear() + '-';
	var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
	var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
	var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
	var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
	var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
	let str = `<text style="font-size: 20px;">` + Y + M + D +
		`</text></br><text style="font-size: 15px;color:#7A7B89;">` + h + m + +s + `</text>`;
	return str;
	// return Y + M + D + h + m + s;
}
export function timeYmd(val) {
	if (!val) {
		return '--'
	}
	var date = new Date(val);
	// var date = new Date(date * 1000);
	var Y = date.getFullYear() + '-';
	var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
	var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
	var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
	var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
	var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
	return Y + M + D + h + m + s;
}

export function strCutting(val, n) {
	if (!val) {
		return '--'
	}
	val = '' + val;
	var leng = val.length;
	val = val.substring(0, n) + '...' + val.substring(leng - n, leng)
	return val
}

// 复制
import { showSuccessToast, showFailToast } from 'vant';
export function copy(val) {
	let isSuccessRef = false;
	//创建input标签
	var input = document.createElement("input");
	//将input的值设置为需要复制的内容
	input.value = val;
	//添加input标签
	document.body.appendChild(input);
	//选中input标签
	input.select();
	//执行复制
	document.execCommand("copy");
	if (document.execCommand("copy")) {
		isSuccessRef = true;
		showSuccessToast({wordBreak:'normal',message:'copy Success'});
	} else {
		isSuccessRef = false;
		showFailToast('copy failed')
	}
	//移除input标签
	document.body.removeChild(input);
	return {isSuccessRef};
}

export function isMobile() {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag;
}