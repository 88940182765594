export const lang = {
  'allowance':'Authorization',
  'cong':'Congratulations',
  'history':'History',
  'menu':{
    'home':'Home',
    'mining':'Traffic Mining',
    'lp':'Liquidity',
    'musicNft':'Great Master NFTs',
    'market':'Genesis Equity NFTs',
    'invite':'My Account',
    'language':'English'
  },
  'home':{
    'h1':'Open blind box to get',
    'h2':'“Great Masters”<br/> series NFT',
    'h3':'box',
    'h4':'Buy Blind Box',
    'h5':'USDT requires authorization, possibly two signatures.',
    'h6':'You may get one of the following NFTs:',
    'h7':'Provided by LoveMusicDAO',
    'h8':'Provided by the sponsor',
    'h9':'About LoveMusicDAO',
    'h10':"LoveMusicDAO is an organization created by early Bitcoin, Ethereum, and Ripple evangelists who love music."
      +"LoveMusicDAO is a distributed traffic aggregator focusing on Web3 ecology. It is a valuable community gathered by users,"
      +" KOLs, media, organizations, and financial institutions from all regions of the world."
      +"Under consensus and collaboration, help underestimated projects in the Web3 industry grow and develop rapidly."
      +"LoveMusicDAO aims to become a Hathaway influential company in the Web3 era.",
    'h11':"The “Great Masters” series NFT to pay tribute to greatness.",
    'h12':"5-Diamond: Nikola Tesla (God of Science), probability 0.2%; traffic mining output 81 times.",
    'h13':'4-Diamond: Ludwig van Beethoven (King of Symphonies), probability 0.8%; traffic mining output 27 times.',
    'h14':'3-Diamond: Elvis Presley (King of Rock & Roll), probability 4%; traffic mining output 9 times.',
    'h15':'2-Diamond: Michael Jackson (King of Pop), probability 25%; traffic mining output 3 times.',
    'h16':'1-Diamond: David Bowie (Legend Musician), probability 70%; traffic mining output 1 times.',
    'h17':'Enter the invitation address',
    'h18':'Invation',
    'h19':'Confirm'
  },
  'mining':{
    'min1':'Read Mining',
    'min2':'My mined',
    'min3':'Rules',
    'min4':'Meteor has issued a total of 100 billion pieces, of which 80 billion pieces are given away to the community for free through flow mining activities.',
    'min5':'1.Users must hold Master NFT in order to participate in flow mining every day to obtain it.',
    'min6':'2.Users who hold Master NFT can get 100 Meteor every day for every 1 times, which will be halved every 180 days, '
      +'and the end of the third year. If the user holds multiple master NFTs, the multiple of the highest-level NFT shall prevail.',
    'min7':'3.Flow mining sharing rewards: 10% for direct sharers, 5% for indirect sharers, Node reward 7% (including 3% for Global Node,'
      +' 5% for Global Big Node, 7% for Global Super Node, differential distribution), genesis equity NFT shares 7%.',
    'min8':'4.LoveMusicDAO will adjust the number and halving time according to the growth of the number of users.',
    'min9':'5.When the event is over, LoveMusicDAO will airdrop the Meteor obtained by the user to the corresponding wallet address.',
    'min10':'Successful mining',
    'min11':'Sharing',
    'min12':'Home',
    'min13':'Accept'
  },
  'lp':{
    'l1':'Liquidity',
    'l2':'Staking',
    'l3':'LP quantity',
    'l4':'LP proportion',
    'l5':'Add',
    'l6':'Remove',
    'l7':'Balance',
    'l8':'Insufficient balance',
    'l9':'Authorization',
    'l10':'Add Liquidity',
    'l11':'Remove Liquidity',
    'l12':'After adding liquidity, you will receive pool tokens representing your equity. These equity tokens can be used as your proof to receive corresponding rewards, and you can redeem them at any time.',
    'l13':'There is no fee to add liquidity here.',
    'l14':'Total staking',
    'l15':'Earned earnings',
    'l16':'Pending',
    'l17':'Staking',
    'l18':'Redeem',
    'l19':'Withdraw',
    'l20':'Staking quantity',
    'l21':'Redemption',
    'l22':'Redemption quantity',
    'l23':'History'
  },
  'nft':{
    'n1':'Current total',
    'n2':'Price',
    'n3':'Buy',
    'n4':'Sell out',
    'n5':'My Genesis Equity NFTs',
    'n6':'Schedule',
    'n7':'Start time',
    'n8':'End time',
    'n9':'Information',
    'n10':'Contract address',
    'n11':'NFT address',
    'n12':'Payment Token address',
    'n13':'Protocol',
    'n14':'About Genesis Equity NFT',
    'n15':"Genesis Equity NFT issued by LoveMusicDAO, which is equivalent to LoveMusicDAO 'Stock Equity' and will receive a share of LoveMusicDAO's operating income.",
    'n16':' Genesis Equity NFT',
    'n17':' Gain 3% of the overall sales of the "Great Masters" series NFT.',
    'n18':' Gain 7% of the overall output of LoveMusicDAO traffic mining.',
    'n19':' Gain a portion of Meteor Token DEX transaction fees.',
    'n41':' The above income is evenly distributed to all current Genesis Equity NFTs',
    'n20':'Earned',
    'n21':'Available',
    'n22':'Withdraw',
    'n23':'Transfer NFT',
    'n24':'After the NFT is transferred, the rewards you have not claimed will be transferred to the new address. It is therefore recommended that you claim your rewards before transferring.',
    'n25':'Transfer',
    'n26':'Nikola Tesla',
    'n27':'Ludwig Van Beethoren',
    'n28':'Elvis Presley',
    'n29':'Michael Jackson',
    'n30':'David Bowie',
    'n31':'NFT name',
    'n32':'Level',
    'n33':'Quantity',
    'n34':'Upgrade',
    'n35':'Rewards available',
    'n36':'Claim',
    'n37':'Three NFTs of the same level can be upgraded to a higher level NFT',
    'n38':'Cancel',
    'n39':'Claim airdrop',
    'n40':'Maximum issue cap',
    'n42':'My Genesis Equity NFT',
    'n43':'Not yet started'     
  },
  'my':{
    'm1':'Total sharing',
    'm2':'Sharing',
    'm3':'Records',
    'm4':'Sharing Reward',
    'm5':'Sharing award',
    'm6':'Lucky award',
    'm7':'Mentoring award',
    'm8':'Withdraw',
    'm9':'Blind box only',
    'm10':'Buy Blind Box',
    'm11':'LoveMusicDAO Nodes',
    'm12':'Withdrawable earnings',
    'm13':'No invitation',
    'm14':'My Sharing Records',
    'm15':'My share link',
    'm16':'Please copy and send the sharing link below to other invitees',
    'm17':'Not yet bought',
    'm18':'Effective Sharing',
    'm19':'Copy'
  },
  'success':'Copy success',
  'fail':'copy failed',
  'max':'MAX',
  'no':'No data',
  'back':'Go Back',
  'details':'Details',

}
