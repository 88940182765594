<template>
	<div id="apploading" v-if="!loading">
		<img src="./assets/logo.png" style="width: 80px;">
		<p>{{loadingt}}</p>
		<p style="font-size: 20px;margin-top: 20px;">VUE</p>
		<div class="bouncing-loader">
			<div></div>
			<div></div>
			<div></div>
		</div>
		<p style="text-align: center;color: #666;">Loading...</p>
	</div>
	<router-view v-else />
</template>

<script setup>
	import {createApp,ref,getCurrentInstance,onMounted} from 'vue';
	const {proxy} = getCurrentInstance();
	import App from './App.vue';
	const app = createApp(App);
	// import Web3 from 'web3';
	// import {getDefaultProvider} from './utils/provider.js';

	const loading = ref(true);

	onMounted(() => {
		
	});

	
</script>

<style>
	/* 字体文件的路径 */
	@font-face {
	  font-family: "DinBold";
	  src: url('./assets/font/DINPro-Bold.otf');
	}
	@font-face {
	  font-family: "DinMedium";
	  src: url('./assets/font/DIN-Medium.otf');
	}
	#app {
		font-family: PingFang SC Regular, 'Avenir', Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		width: 100%;
		min-height: 100vh;
		color: #333;
		font-size: 16px;
		background-color: #fff;
		/* user-select: none; */
	}
	body::-webkit-scrollbar {
	  width: 0px;
	  height: 0px;
	}

	#apploading {
		position: fixed;
		width: 100vw;
		height: 300px;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.loading {
		width: 100%;
		height: 100%;
		position: fixed;
		z-index: 9999;
		background-color: rgba(0, 0, 0, 0);
		top: 0;
	}

	@keyframes bouncing-loader {
		to {
			opacity: 0.1;
			transform: translate3d(0, -1rem, 0);
		}
	}

	.bouncing-loader {
		display: flex;
		justify-content: center;
	}

	.bouncing-loader>div {
		width: 1rem;
		height: 1rem;
		margin: 3rem 0.2rem;
		background: #8385aa;
		border-radius: 50%;
		animation: bouncing-loader 0.6s infinite alternate;
	}

	.bouncing-loader>div:nth-child(2) {
		animation-delay: 0.2s;
	}

	.bouncing-loader>div:nth-child(3) {
		animation-delay: 0.4s;
	}
	
	
	
	.van-slider__bar {
		background: #FF7248 !important;
	}
	.van-slider__button {
		background: linear-gradient(135deg,#c859cc 0%, #2559fa) !important;
		border: 4px solid #ffffff;
	}
	
	.van-popover__action {
		width: 200px !important;
		font-family: DinBold;
		font-weight: normal;
		font-size: 12px;
		color: #222222;
		line-height: 18px;
	}
</style>
